<template>
  <div>
    <canvas
      id="myCanvas"
      width="55"
      height="20"
    >{{ $t('testquestioncaptcha.yourBrowserDoes') }}</canvas>
    <v-text-field
      v-model="output"
      :rules="[
        validations.required($t('testquestioncaptcha.captcha')),
        correctOutput
      ]"
      persistent-hint
      :hint="$t('testquestioncaptcha.hint')"
      type="number"
      class="py-0"
    />
  </div>
</template>

<script>
  import validations from '@/components/formUtils/Validations'
  export default {
    name: 'TestQuestionCaptcha',
    data () {
      return {
        input1: 0,
        input2: 0,
        output: '',
        validations,
      }
    },
    computed: {
      correctOutput () {
        return parseInt(this.input1) + parseInt(this.input2) === parseInt(this.output) || 'Das Ergebnis stimmt nicht.'
      },
      testQuestionlabel () {
        return this.input1 + ' + ' + this.input2
      },
    },
    mounted () {
      this.input1 = this.getRandomInt(9)
      this.input2 = this.getRandomInt(9 - parseInt(this.input1))

      // HIGH RESOLUTION CANVAS
      // var canvas = document.getElementById('myCanvas')
      // var ctx = canvas.getContext('2d')
      // window.devicePixelRatio = 2

      // canvas.style.width = 50 + 'px'
      // canvas.style.height = 20 + 'px'

      // var scale = window.devicePixelRatio
      // canvas.width = Math.floor(50 * scale)
      // canvas.height = Math.floor(20 * scale)

      // ctx.scale(scale, scale)
      // ctx.font = '15px Arial'
      // ctx.textAlign = 'center'
      // ctx.textBaseline = 'middle'

      // var x = 55 / 2
      // var y = 20 / 2

      // LOW RESOLUTION CANVAS
      var canvas = document.getElementById('myCanvas')
      var ctx = canvas.getContext('2d')
      ctx.font = '15px Arial'

      ctx.fillStyle = '#329c36'
      ctx.fillText(this.testQuestionlabel, 0, 15)
    },
    methods: {
      getRandomInt (max) {
        return Math.floor(Math.random() * Math.floor(max) + 1)
      },
    },
  }
</script>

<style scoped>
  #myCanvas {
    margin-bottom: -12px;
  }
</style>
