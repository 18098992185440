<template>
  <div>
  <header>
    <img class="wave-header" :src="require('../assets/layout-waves/top-decoration.svg')" alt="Top Decoration">
  </header>
    <v-main class="wave-content">
      <slot/>
    </v-main>
    <footer class="outer-footer">
      <img class="wave-footer" :src="require('../assets/layout-waves/bottom-decoration.svg')" alt="Bottom Decoration">
    </footer>
  </div>
</template>

<script>
export default {
  name: 'WaveLayout'
}
</script>

<style>
.wave-header{
  height: 192px;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  z-index: 10;
}

.wave-footer{
  height: 251px;
  width: 100%;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
}

@media only screen and (max-width: 1024px) {
  .wave-header {
    object-fit: cover;
    object-position: top -10px left -236px;
  }
  .wave-footer {
    object-fit: cover;
    object-position: bottom 0 left -390px;
    bottom: 60px;
    position: relative;
  }

  footer.outer-footer{
    height: 100px;
    width: 100%;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
  }
}

.wave-content {
  min-height: 100vh;
  align-items: center;
  margin-top: -128px;
}

@media (min-width: 1904px){
  .wave-content {
    margin-top: -56px;
  }
}

@media(max-width: 960px){
  .wave-content {
    margin-top: -30px;
  }
}

</style>
