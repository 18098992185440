const confidantModule = {
    namespaced: true,
    state: {
        confidant: undefined,
        confidants: undefined,
        confidantsLeft: undefined,
        selectedConfidant: undefined,
    },
    getters: {
    },
    mutations: {
        setConfidant (state, payload) {
            state.confidant = payload
        },
       setConfidants (state, payload) {
            state.confidants = payload
        },
       setConfidantsLeft (state, payload) {
            state.confidantsLeft = payload
        },
       setSelectedConfidant (state, payload) {
            state.selectedConfidant = payload
        },
    },
    actions: {},
}

export default confidantModule
