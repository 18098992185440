const settingsModule = {
  namespaced: true,
  state: {
    customer: undefined,
    installEvent: undefined,
  },
  getters: {
  },
  mutations: {
    setCustomer (state, payload) {
      state.customer = payload
    },
    setInstallEvent (state, payload) {
      state.installEvent = payload
    },
  },
  actions: {},
}

export default settingsModule
