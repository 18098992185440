const emailPattern = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/
const mobileNrPattern = /^[0-9]{2}[0-9]{11,12}$/im
const germanDatePattern = /^([0-2]\d|3[01])\.([0]\d|1[0-2])\.\d{4}$/
const specialCharactersPattern = /^[a-zA-Z0-9-\säöüÄÖÜß]{1,30}$/

export default {

    required: (message = 'Dieses Feld ist erforderlich') => (value) => {
        if (!value || String(value).match(/^ *$/) !== null) {
            return message
        }
        return true
    },
    maxLength: (max, message = 'Der Wert ist zu lang') => (value) => {
        if (value.length <= max) {
            return true
        }
        return message
    },
    minLength: (min, message = 'Der Wert ist zu kurz') => (value) => {
        if (value.length >= min) {
            return true
        }
        return message
    },
    isMinLength: (value, min) => {
        if (value.length >= min) {
            return true
        }
        return false
    },
    email: (message = 'Ungültige E-Mail-Adresse') => (value) => {
        return emailPattern.test(value) || message
    },
    isEmail: (value) => {
        return emailPattern.test(value) || false
    },
    mobileNr: (message = 'Ungültige Mobil-Nummer') => (value) => {
        return value ? (mobileNrPattern.test(value) || message) : true
    },
    password: (messages) => (value) => {
        if (value) {
            if (value.length < 8) {
                return messages.password1
            } else if (value.length > 100) {
                return messages.password2
            } else if (value.search(/\d/) === -1) {
                return messages.password3
            } else if (value.search(/[a-z]/) === -1) {
                return messages.password4
            } else if (value.search(/[A-Z]/) === -1) {
                return messages.password5
            } else if (value.search(/[!@#$%^&*()_+.,;:]/) === -1) {
                return messages.password6
            } else if (value.search(/[^a-zA-Z0-9!@#$%^&*()_+.,;:]/) !== -1) {
                return messages.password7
            }
            return true
        } else {
            return false
        }
    },
    isPassword: (value) => {
        if (value) {
            if (value.length < 8 || value.length > 100 || value.search(/\d/) === -1 || value.search(/[A-Z]/) === -1 || value.search(/[!@#$%^&*()_+.,;:]/) === -1 || value.search(/[^a-zA-Z0-9!@#$%^&*()_+.,;:]/) !== -1) {
                return false
            }
            return true
        } else {
            return false
        }
    },
    number: (messages, min, max) => (value) => {
        if (!isNaN(value)) {
            return messages.NaN
        }

        if (min !== undefined && value < min) {
            return messages.min + value
        }

        if (max !== undefined && value > max) {
            return messages.max + value
        }

        return true
    },
    integer: (messages, min, max) => (value) => {
      const number = parseInt(value)
      if (isNaN(number)) {
        return messages.NaN
      }

      if (min !== undefined && number < min) {
        return messages.min
      }

      if (max !== undefined && number > max) {
        return messages.max
      }
      return true
    },
    date: (message = 'Ungültiges Datum') => (value) => {
        return germanDatePattern.test(value) || message
    },
    noSpecialCharacters: (message = 'Ungültiges Zeichen') => (value) => {
        if (value.length === 0) {
            return true
        }
        return specialCharactersPattern.test(value) || message
    },
    checkPositiveNumber: (message = 'Wert kann nicht negativ sein') => (value) => {
        if (value >= 0) {
            return true
        }
        return message
    },
    isCheckPositiveNumber: (value) => {
        if (value >= 0 && value !== '') {
            return true
        }
        return false
    },
}
