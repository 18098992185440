<template>
  <div class="locale-changer">
    <v-select
      v-model="selectedLocale"
      :items="$i18n.availableLocales"
      dense
      class="languageSelectBar"
      @change="updateLocale"
    >
      <template v-slot:selection="{ item }">
        <v-img
          :src="getImgUrl(item)"
          alt="language"
          max-width="40"
          contain
        />
      </template>
      <template v-slot:item="{ item }">
        <v-img
          :src="getImgUrl(item)"
          alt="language"
          max-width="40"
          contain
        />
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'GlobalLanguageSelector',
  data() {
    return {
      selectedLocale: this.$i18n.locale
    }
  },
  methods: {
    getImgUrl(lang) {
      return require('@/assets/' + lang + '.webp')
    },
    updateLocale() {
      this.$i18n.locale = this.selectedLocale
      localStorage.setItem('locale', this.selectedLocale)
      this.$emit('locale-changed', this.selectedLocale)
    }
  }
}
</script>

<style>
.languageSelectBar.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.languageSelectBar.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
.languageSelectBar .mdi-menu-down::before {
  margin-left: 0rem !important;
}

@media only screen and (max-width: 900px ) {
    .languageSelectBar .mdi-menu-down::before {
        margin-left: 70px !important;
    }
}
</style>