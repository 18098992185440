<template>
  <v-dialog
    v-model="openDialog"
    width="650"
  >
    <v-card class="emcomy-card">
      <login-form-component @closeForm="close()"></login-form-component>
    </v-card>
  </v-dialog>
</template>

<script>
import RequestPasswordChange from '@/views/anonymousPages/components/RequestPasswordChangeKeyComponent.vue'
import SuspendAccountDialog from '@/views/anonymousPages/components/SuspendedAccountDialogComponent.vue'
import RequestPasswordOrIdForgotten from '@/views/anonymousPages/components/RequestPasswordOrIdForgottenComponent.vue'
import validations from '@/components/formUtils/Validations'
import router from '@/router'
import LoginFormComponent from '@/views/anonymousPages/components/LoginFormComponent.vue'

export default {
  components: {
    LoginFormComponent,
    RequestPasswordChange,
    RequestPasswordOrIdForgotten,
    SuspendAccountDialog,
  },
  data () {
    return {
      customer: 'customer',
      confidant: 'confidant',
      loginAs: 'customer',
      openDialog: false,
      showPassword: false,
      email: '',
      password: '',
      pin: '',
      customerId: '',
      validations,
      router,
      MAX_LOGIN_ATTEMPTS: 5,
    }
  },
  computed: {
    logoPictureSource () {
      const locale = this.$root.$i18n.locale
      if (locale === 'pt' || locale === 'ptBR') {
        return require('@/assets/emcomyLogo-pt.webp')
      }
      return require('@/assets/emcomyLogo.svg')
    },
  },
  methods: {
    open () {
      this.openDialog = true
    },
    close () {
      this.openDialog = false
    },
  },
}
</script>
