import http from './httpService'

const basePath = '/public'

const PublicService = {
  // PASSWORD RESET
  isEmailPending (email) {
    return http.get(basePath + '/' + email + '/isPending')
  },
  isEmailVerified (email) {
    return http.get(basePath + '/' + email + '/isVerified')
  },
  requestPasswordChangeKey (email) {
    return http.post(basePath + '/passwordReset/' + email)
  },
  isPasswordChangeKeyValid (passwordChangeKey) {
    return http.get(basePath + '/passwordReset/' + passwordChangeKey)
  },
  resetPassword (resetPasswordDto) {
    return http.post(basePath + '/passwordReset', resetPasswordDto)
  },
  // CONTACT US
  sendContactUsEmails (contactUsDto) {
    return http.post(basePath + '/contactUs', contactUsDto)
  },
  // LOGIN ATTEMPTS
  increaseLoginAttempts (email) {
    return http.post(basePath + '/' + email + '/loginAttempt')
  },
  // GENERATE 2FA
  generate2FA (email) {
    return http.post(basePath + '/' + email + '/2FA')
  },
}

export default PublicService
