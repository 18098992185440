<template>
  <v-dialog
    v-model="openDialog"
    width="400"
    persistent
  >
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12">
            {{ $t('views.anonymouspages.suspendedaccountdialogcomponent.ihrKontoWurde') }}
            <a @click="openRequestPasswordChangeKeyDialog">
              {{ $t('views.anonymouspages.suspendedaccountdialogcomponent.passwortVergessen') }}
            </a>
            {{ $t('views.anonymouspages.suspendedaccountdialogcomponent.funktion') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              class="text-none"
              color="primary"
              @click="close"
            >
              Ok
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <request-password-change
      ref="requestPasswordChangeDialog"
    />
  </v-dialog>
</template>

<script>
  import RequestPasswordChange from '@/views/anonymousPages/components/RequestPasswordChangeKeyComponent.vue'

  export default {
    components: {
      RequestPasswordChange,
    },
    data () {
      return {
        openDialog: false,
      }
    },
    methods: {
      open () {
        this.openDialog = true
      },
      close () {
        this.openDialog = false
      },
      openRequestPasswordChangeKeyDialog () {
        this.close()
        this.$refs.requestPasswordChangeDialog.open()
      },
    },
  }
</script>
