const loginModule = {
  namespaced: true,
  state: {
    loginDto: undefined,
  },
  getters: {
  },
  mutations: {
    setLoginDto (state, payload) {
      state.loginDto = payload
    }
  },
  actions: {},
}

export default loginModule
