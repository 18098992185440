<template>
  <div class="layout-container">
    <customer-page-bar />
    <v-row
      class="primary customer-layout"
    >
      <v-container
        fluid
        class="customer-layout-child"
      >
        <v-toolbar-items
          class="py-3 float-right pr-5"
        >
          <span class="text-h4 white--text font-weight-bold">{{ $t('layouts.customerpagelayout.hallo', { '1': name }) }}</span>
        </v-toolbar-items>
      </v-container>
    </v-row>
    <v-main class="customer-container">
      <slot />
    </v-main>
    <br>
    <br>
    <customer-page-footer />
  </div>
</template>

<script>
  import CustomerPageBar from '@/layouts/CustomerPageBar.vue'
  import CustomerPageFooter from '@/layouts/CustomerPageFooter.vue'
  import CustomerService from '@/services/CustomerService'
  import getBrowserLocale from '@/util/get-browser-locale'

  export default {
    name: 'CustomerPageLayout',
    components: {
      CustomerPageBar,
      CustomerPageFooter,
    },
    data () {
      return {
        name: sessionStorage.getItem('name'),
      }
    },
    created () {
      CustomerService.getCustomerInfos()
        .then((response) => {
          const customer = response.data
          if (customer.language) {
            this.$root.$i18n.locale = customer.language.locale
          }
        })
    },
  }
</script>

<style>
  .layout-container {
    margin-top: 56px !important;
  }
  .customer-container {
    min-height: 84.6vh;
  }

  @media only screen and (max-width: 1045px ) {
    .customer-layout {
      margin-top: 1rem;
    }
    .customer-layout-child {
      margin-top: -0.6rem;
    }
}
</style>
