import Vue from 'vue'
import Vuex from 'vuex'
import contactModule from './store/contactModule'
import confidantModule from './store/confidantModule'
import settingsModule from './store/settingsModule'
import defaultTemplateModule from './store/defaultTemplateModule'
import languageModule from './store/languageModule'
import adminModule from './store/adminModule'
import loginModule from '@/store/loginModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    contactModule,
    confidantModule,
    settingsModule,
    defaultTemplateModule,
    languageModule,
    adminModule,
    loginModule,
  },
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    drawer: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
  },
  actions: {

  },
})
