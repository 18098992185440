import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from 'vuetify/lib/locale/en'
import de from 'vuetify/lib/locale/de'
import es from 'vuetify/lib/locale/es'
import pt from 'vuetify/lib/locale/pt'
import getBrowserLocale from '@/util/get-browser-locale'

Vue.use(VueI18n)

const messages = {
  en: {
    ...require('@/locales/en.json'),
    $vuetify: en,
  },
  de: {
    ...require('@/locales/de.json'),
    $vuetify: de,
  },
  es: {
    ...require('@/locales/es.json'),
    $vuetify: es,
  },
  pt: {
    ...require('@/locales/pt.json'),
    $vuetify: pt,
  },
  ptBR: {
    ...require('@/locales/pt-br.json'),
    $vuetify: pt,
  },
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || localStorage.getItem('locale') || getBrowserLocale({ countryCodeOnly: true }) || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages,
})
