<template>
  <div>
    <empty-page-bar />
    <v-main>
      <slot />
    </v-main>
  </div>
</template>

<script>
  import EmptyPageBar from '@/layouts/EmptyPageBar.vue'

  export default {
    name: 'EmptyPageLayout',
    components: {
      EmptyPageBar,
    },
  }
</script>

<style scoped>

</style>
