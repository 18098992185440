import { render, staticRenderFns } from "./ConfidantPageBar.vue?vue&type=template&id=2f7bc878&"
import script from "./ConfidantPageBar.vue?vue&type=script&lang=js&"
export * from "./ConfidantPageBar.vue?vue&type=script&lang=js&"
import style0 from "./ConfidantPageBar.vue?vue&type=style&index=0&id=2f7bc878&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports