<template>
  <v-footer
    class="ma-0 pa-0"
    color="footer"
    justify="center"
    align="start"
    padless
    app
    absolute
  >
    <v-container>
      <v-row>
        <v-col
          v-for="item in footerItems"
          :key="item.header"
          cols="12"
          md="4"
          sm="6"
        >
          <v-row class="row--dense ma-0 pa-0">
            <v-col cols="12">
              <span class="white--text font-weight-black">{{ item.header }}</span>
            </v-col>
            <v-col
              v-for="link in item.links"
              :key="link.title"
              cols="12"
            >
              <a
                v-if="link.title === 'Login'"
                color="white"
                text
                rounded
                class="white--text"
                @click="openLoginDialog"
              >
                {{ link.title }}
              </a>
              <router-link
                v-else
                :to="link.link"
                class="white--text"
              >
                {{ link.title }}
              </router-link>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-for="item in externalItems"
          :key="item.header"
          cols="12"
          md="4"
          sm="6"
        >
          <v-row class="row--dense ma-0 pa-0">
            <v-col cols="12">
              <span class="white--text font-weight-black">{{ item.header }}</span>
            </v-col>
            <v-col
              v-for="link in item.links"
              :key="link.title"
              cols="12"
            >
              <a
                :href="link.link"
                target="_blank"
                class="white--text"
              >
                {{ link.title }}
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<style lang="css" scoped>
  .v-btn:before {
    bottom: unset !important;
  }
</style>

<script>

  export default {
    data () {
      return {
        externalItems: [
          {
            header: 'soziale medien',
            links: [
              { title: 'Facebook', link: 'https://www.facebook.com/Emcomy-101616245005602/' },
              { title: 'Instagram', link: 'https://www.instagram.com/emcomy' },
              { title: 'Twitter', link: 'https://www.twitter.com/Emcomy1' },
            ],
          },
        ],
      }
    },
    computed: {
      footerItems () {
        return [
          {
            header: 'emcomy',
            links: [
              { title: this.$t('layouts.core.appfooteranonymous.news'), link: '/news' },
              { title: this.$t('layouts.core.appfooteranonymous.contact-us'), link: '/contact-us' },
              { title: this.$t('layouts.core.appfooteranonymous.faqs'), link: '/faqs' },
            ],
          },
          {
            header: this.$t('layouts.core.aboutUs'),
            links: [
              { title: this.$t('layouts.core.appfooteranonymous.whoWeAre'), link: '/whoWeAre' },
              { title: this.$t('layouts.core.appfooteranonymous.imprint'), link: '/imprint' },
              { title: this.$t('layouts.core.appfooteranonymous.termsOfUse'), link: '/termsOfUse' },
              { title: this.$t('layouts.core.appfooteranonymous.privacy'), link: '/privacy' },
            ],
          },
        ]
      },
    },
  }
</script>
