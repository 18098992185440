<template>
  <router-link
    :to="to"
    tag="span"
    style="cursor: pointer"
  >
    <v-img
      :src="emcomyLogo"
      alt="emcomy - Emergency Communicator"
      contain
      max-width="250"
      class="logo"
    />
  </router-link>
</template>

<script>
  export default {
    name: 'LogoLink',
    props: {
      to: {
        type: String,
        default: '/overview',
      },
    },
    computed: {
      emcomyLogo() {
        const locale = this.$root.$i18n.locale
        if (locale === 'pt' || locale === 'ptBR') {
          return require('@/assets/emcomyLogo-pt.webp')
        }
        return require('@/assets/emcomyLogo.svg')
      },
    },
  }
</script>
