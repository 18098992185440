const contactModule = {
    namespaced: true,
    state: {
        contacts: undefined,
        contactsLeft: undefined,
        selectedContact: undefined,
    },
    getters: {
    },
    mutations: {
       setContacts (state, payload) {
            state.contacts = payload
       },
       setContactsLeft (state, payload) {
            state.contactsLeft = payload
        },
       setSelectedContact (state, payload) {
            state.selectedContact = payload
        },
    },
    actions: {},
}

export default contactModule
