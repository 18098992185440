<template>
  <v-app>
    <component :is="$route.meta.layout || 'div'">
      <div>
        <router-view />
      </div>
    </component>
    <notifications />
    <cookie-consent-dialog
      ref="cookieConsentDialog"
    />
  </v-app>
</template>

<script>
  import CookieConsentDialog from '@/components/CookieConsentDialog.vue'
  import NotificationService from '@/services/NotificationService'
  import LanguageService from '@/services/LanguageService'

  export default {
    name: 'App',
    components: {
      CookieConsentDialog,
    },
    data () {
      return {
        userWantsCookies: 'false',
        customer: {},
      }
    },
    mounted () {
      // we define a listener to reset a timer every time a click is made on the app
      const self = this
      var idleSeconds = 0
      document.addEventListener('mouseup', e => {
        idleSeconds = 0
      })

      // if the time exceeds a certain limit and teh user is logged in, the app will automatically logout the user and reload the page
      setInterval(function () {
        idleSeconds++
        if (idleSeconds > process.env.VUE_APP_IDLE_TIME && sessionStorage.token) {
          self.doLogout()
          location.reload()
        }
      }, 1000)

      // every time the app is loaded we check if the user wants cookies, otherwise we ask him if he wants until he agrees or leaves the page
      this.userWantsCookies = this.$cookies.isKey('wantsCookies') ? this.$cookies.get('wantsCookies') : false
      if (!(this.userWantsCookies === 'true')) {
        this.$refs.cookieConsentDialog.open()
      }

      this.userWantsEssentialCookies = this.$cookies.isKey('wantsEssentialCookies') ? this.$cookies.get('wantsEssentialCookies') : false
      if (this.userWantsEssentialCookies === 'true') {
        // placeholder
      }

      this.userWantsAnalysisCookies = this.$cookies.isKey('wantsAnalysisCookies') ? this.$cookies.get('wantsAnalysisCookies') : false
      if (this.userWantsAnalysisCookies === 'true') {
        const analyticsScript = document.createElement('script')
        analyticsScript.setAttribute('async', true)
        analyticsScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=<%= VUE_APP_GOOGLE_ANLYTICS_ID %>')
        document.head.appendChild(analyticsScript)
      }
    },
    created () {
        window.addEventListener('beforeinstallprompt', (event) => {
          event.preventDefault()
          this.$store.commit('settingsModule/setInstallEvent', event)
        })
        LanguageService.getAllLanguages()
        .then((response) => {
          const untranslatedLanguages = response.data
          this.$store.commit('languageModule/setTranslatedLanguages', untranslatedLanguages)
        })
        .catch((error) => NotificationService.error(error.message))
    },
    methods: {
      doLogout () {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('isCustomer')
        sessionStorage.removeItem('isAdmin')
        sessionStorage.removeItem('name')
        window.location.reload()
      },
    },
  }
</script>

<style>
  @font-face {
    font-family: "Montserrat";
    src: local("Montserrat"),
    url("./assets/fonts/Montserrat.woff2") format("woff2");
  }

  .cursor-pointer {
    cursor: pointer
  }

  ul li{
    margin: 1rem 0;
    margin-left: -10px;
  }

  .v-main {
    max-width: 100% !important;
  }

  .v-application--wrap {
    overflow-x: clip !important;
    min-height: 95vh !important;
  }

  @media (min-width: 960px) {
    .container {
      max-width: 1100px !important;
    }
  }

  @media (max-width: 960px) {
    ul li{
      margin: 1rem 0;
      margin-left: -23px;
    }
  }

</style>
