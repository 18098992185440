import http from './httpService'

const basePath = '/language'

const LanguageService = {
    getAllLanguages () {
        return http.get(basePath)
    },
}

export default LanguageService
