<template>
  <v-dialog
    v-model="openDialog"
    width="400"
    persistent
  >
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12">{{ $t('views.anonymouspages.linksentdialogcomponent.derLinkZum') }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              class="text-none"
              color="primary"
              @click="close"
            >
              Ok
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data () {
      return {
        openDialog: false,
      }
    },
    methods: {
      open () {
        this.openDialog = true
      },
      close () {
        this.openDialog = false
        window.location.reload()
      },
    },
  }
</script>
