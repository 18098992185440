const defaultTemplateModule = {
    namespaced: true,
    state: {
        defaultTemplates: undefined,
        selectedDefaultTemplates: undefined,
    },
    getters: {
    },
    mutations: {
       setDefaultTemplates (state, payload) {
            state.defaultTemplates = payload
       },
       setSelectedDefaultTemplates (state, payload) {
            state.selectedDefaultTemplates = payload
       },
    },
    actions: {},
}

export default defaultTemplateModule
