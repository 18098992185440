<template>
  <div>
    <v-app-bar
      id="app-bar-empty"
      color="white"
      :prominent="$vuetify.breakpoint.mdAndUp"
      fixed
      flat
    >
      <v-container>
        <v-row class="justify-center">
          <!-- Start Logo -->
          <v-col
            cols="12"
            md="3"
          >
            <v-toolbar-title>
              <logo-link />
            </v-toolbar-title>
          </v-col>
          <!-- End Logo -->
        </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
  import LogoLink from '../components/LogoLink.vue'

  export default {
    components: { LogoLink },
  }
</script>
