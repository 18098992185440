const adminModule = {
    namespaced: true,
    state: {
        admins: undefined,
    },
    getters: {
    },
    mutations: {
       setAdmins (state, payload) {
            state.admins = payload
       },
    },
    actions: {},
}

export default adminModule
