import http from './httpService'

const basePath = '/confidant'

const ConfidantService = {
    addConfidant (creatingConfidantDto) {
      return http.post(basePath, creatingConfidantDto)
    },
    getAllConfidants () {
      return http.get(basePath)
    },
    updateConfidant (updatingConfidantDto) {
      return http.put(basePath, updatingConfidantDto)
    },
    deleteConfidant (id) {
      return http.delete(basePath + '/' + id)
    },
    getConfidantsLeft () {
        return http.get(basePath + '/confidantsLeft')
    },
    updatePin (id) {
      return http.post(basePath + '/updatePin/' + id)
    },
    // Confidant Actions:
    getCustomerSelectedDefaultTemplates () {
      return http.get(basePath + '/selectedDefaultTemplates')
    },
    getConfidantInfos () {
      return http.get(basePath + '/loggedInConfidant')
    },
    updateFirstLogin () {
      return http.post(basePath + '/updateFirstLogin')
    },
}

export default ConfidantService
