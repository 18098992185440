<template>
  <v-dialog
    v-model="openDialog"
    width="650"
  >
    <v-card>
      <v-form ref="form">
        <v-card-title class="pt-2">
          <v-row>
            <v-col
              md="6"
              offset-md="3"
            >
              <v-img
                :src="logoPictureSource"
                contain
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <div class="primary--text">
            {{ $t('views.anonymouspages.requestpasswordoridforgottencomponent.hinweis') }}
          </div>
          <div class="pt-2">
            {{ $t('views.anonymouspages.requestpasswordoridforgottencomponent.dieZugangsdatenBefinden') }}
            <br><br>{{ $t('views.anonymouspages.requestpasswordoridforgottencomponent.solltenSieDieses') }}<br><br>
            {{ $t('views.anonymouspages.requestpasswordoridforgottencomponent.esGibtKeine') }}<br><br><br>
          </div>
          <v-btn
            block
            color="#75b842"
            @click="close"
          >
            {{ $t('views.anonymouspages.requestpasswordoridforgottencomponent.verstanden') }}
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data () {
      return {
        openDialog: false,
      }
    },
    computed: {
      logoPictureSource () {
        const locale = this.$root.$i18n.locale
        if (locale === 'pt' || locale === 'ptBR') {
          return require('@/assets/emcomyLogo-pt.webp')
        }
        return require('@/assets/emcomyLogo.svg')
      },
    },
    methods: {
      open () {
        this.openDialog = true
      },
      close () {
        this.openDialog = false
      },
    },
  }
</script>
