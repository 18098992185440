import http from './httpService'

const basePath = '/customer'

const CustomerService = {
  isEmailAvailable (email) {
    return http.get(basePath + '/' + email + '/isAvailable')
  },
  getCustomerInfos () {
    return http.get(basePath + '/loggedInCustomer')
  },
  getVaucherActiveInfo () {
    return http.get('/vaucher/verify-user-with-vaucher-active')
  },
  updatePersonalSettings (updatingPersonalSettingsDto) {
    return http.put(basePath, updatingPersonalSettingsDto)
  },
  updatePassword (updatingPasswordDto) {
    return http.post(basePath + '/updatePassword', updatingPasswordDto)
  },
  updateNewsletterPreference (updatingPersonalSettingsDto) {
    return http.put(basePath + '/updateNewsletterPreference', updatingPersonalSettingsDto)
  },
  deleteCustomer () {
    return http.delete(basePath)
  },
  selectDefaultTemplate (id) {
    return http.post(basePath + '/selectDefaultTemplate/' + id)
  },
  deselectDefaultTemplate (id) {
    return http.post(basePath + '/deselectDefaultTemplate/' + id)
  },
  getSelectedDefaultTemplates () {
    return http.get(basePath + '/selectedDefaultTemplates')
  },
  getIntervalTimer () {
    return http.get('/self-protection/interval-list')
  },
  saveSelfProtection (selfProtection) {
    return http.post('/self-protection', selfProtection);
  },
  getSelfProtection () {
    return http.get('/self-protection');
  }
}

export default CustomerService
