const languageModule = {
    namespaced: true,
    state: {
        translatedLanguages: [],
    },
    getters: {
    },
    mutations: {
        setTranslatedLanguages (state, payload) {
            state.translatedLanguages = payload
        },
    },
    actions: {},
}

export default languageModule
