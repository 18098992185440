import http from './httpService'

const basePath = '/websiteActivity'

const WebActivityService = {
    registerVisitRecord (visitRecordDto) {
        return http.post(basePath + '/registerVisitRecord', visitRecordDto)
    },
}

export default WebActivityService
