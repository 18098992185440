<template>
  <div>
    <confidant-page-bar />
    <v-row
      class="primary"
    >
      <v-container>
        <v-toolbar-items
          class="py-3 float-right pr-5"
        >
          <span class="text-h4 white--text font-weight-bold">{{ this.$t('emergency-notification.hello') + " " + name }} </span>
        </v-toolbar-items>
      </v-container>
    </v-row>
    <v-main>
      <slot />
    </v-main>
    <confidant-page-footer />
  </div>
</template>

<script>
  import ConfidantPageBar from '@/layouts/ConfidantPageBar.vue'
  import ConfidantPageFooter from '@/layouts/ConfidantPageFooter.vue'
  import ConfidantService from '@/services/ConfidantService'
  import NotificationService from '@/services/NotificationService'

  export default {
    name: 'ConfidantPageLayout',
    components: {
      ConfidantPageBar,
      ConfidantPageFooter,
    },
    data () {
      return {
        name: sessionStorage.getItem('name'),
      }
    },
    created () {
      ConfidantService.getConfidantInfos()
        .then((response) => {
          this.$root.$i18n.locale = response.data.messageLanguage.locale
        })
        .catch((error) => NotificationService.error(error.message))
    },
  }
</script>
