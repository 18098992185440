<template>
  <v-footer
    class="ma-0 pa-0"
    color="footer"
    justify="center"
    align="start"
    padless
    app
    absolute
  >
    <div class="footer-container">
      <v-row class="anonymous-footer-parent">
        <v-col
          cols="12"
          md="7"
          class="flex-center icons-heading"
        >
          {{ $t('layouts.core.appfooteranonymous.folgeUnsAuf') }}
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="flex-center icons-box"
        >
          <v-row class="flex-center">
            <div>
              <a
                href="https://www.facebook.com/Emcomy-101616245005602"
                target="_blank"
                class="icon-link"
              >
                <v-icon
                  color="white"
                  class="footer-icon"
                >
                  mdi-facebook
                </v-icon>
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/emcomy/"
                target="_blank"
                class="icon-link"
              >
                <v-icon
                  color="white"
                  class="footer-icon"
                >
                  mdi-instagram
                </v-icon>
              </a>
            </div>
            <div>
              <a
                href="https://www.pinterest.de/emcomyGmbH"
                target="_blank"
                class="icon-link"
              >
                <v-icon
                  color="white"
                  class="footer-icon"
                >
                  mdi-pinterest
                </v-icon>
              </a>
            </div>
            <div>
              <a
                href="https://www.linkedin.com/company/emcomy-gmbh"
                target="_blank"
                class="icon-link"
              >
                <v-icon
                  color="white"
                  class="footer-icon"
                >
                  mdi-linkedin
                </v-icon>
              </a>
            </div>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="links-wrap"
        >
          <v-row>
            <v-col
              v-for="item in footerItems"
              :key="item.header"
              cols="12"
              md="6"
              class="footer-links"
            >
              <v-row class="row--dense ma-0 pa-0">
                <v-col
                  v-for="link in item.links"
                  :key="link.title"
                  cols="12"
                >
                  <router-link
                    :to="link.link"
                    class="white--text"
                  >
                    {{ link.title }}
                  </router-link>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-container />
  </v-footer>
</template>

<style lang="css" scoped>
  .v-btn:before {
    bottom: unset !important;
  }
</style>

<script>

  export default {
    computed: {
      footerItems () {
        return [
          {
            header: 'emcomy',
            links: [
              { title: this.$t('layouts.core.appfooteranonymous.imprint'), link: '/imprint' },
              { title: this.$t('layouts.core.appfooteranonymous.termsOfUse'), link: '/termsOfUse' },
              { title: this.$t('layouts.core.appfooteranonymous.privacy'), link: '/privacy' },
              { title: this.$t('layouts.core.appfooteranonymous.security'), link: '/security' },
            ],
          },
          {
            header: this.$t('layouts.core.aboutUs'),
            links: [
              { title: this.$t('layouts.core.appfooteranonymous.overview'), link: '/login' },
              { title: this.$t('layouts.core.appfooteranonymous.register'), link: '/register' },
              { title: this.$t('layouts.core.appfooteranonymous.contact-us'), link: '/contact-us' },
              { title: this.$t('layouts.core.appfooteranonymous.news'), link: '/news' },
            ],
          },
        ]
      },
    },
  }
</script>

<style scoped>
  .anonymous-footer-parent {
    padding-top: 2rem;
    padding-bottom: 0.5rem;
  }
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-container {
    width: 100%;
  }
  .mdi:before {
      font-size: 50px !important;
      margin: 0 8px;
  }
  .icons-heading {
    height: 0px;
    margin-bottom: -2rem;
    margin-left: -6rem;
    color: white;
  }

  @media only screen and (max-width: 950px ) {
    .footer-links {
      text-align: center;
    }
    .icons-heading {
      margin: 1.5rem 0;
    }
  }

  @media only screen and (max-width: 600px ) {
    .icon-link {
      padding: 0;
    }
  }

  @media only screen and (max-width: 400px ) {
    .icons-box {
      margin-left: -0.8rem;
    }
  }
</style>
