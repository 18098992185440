<template>
  <v-footer
    class="ma-0 pa-0 customer-footer"
    color="footer"
    justify="center"
    align="start"
    app
    absolute
  >
    <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <div class="links-container">
            <div 
              v-for="(link, index) in links"
              :key="link.title"
              class="link-item"
            >
              <router-link
                :to="link.link"
                class="white--text"
              >
                {{ link.title }}
              </router-link>
              <span v-if="index < links.length - 1" class="separator">|</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    data () {
      return {
        links: [
          { title: this.$t('layouts.core.appfooteranonymous.imprint'), link: '/imprint' },
          { title: this.$t('layouts.core.appfooteranonymous.termsOfUse'), link: '/termsOfUse' },
          { title: this.$t('layouts.core.appfooteranonymous.privacy'), link: '/privacy' },
        ],
      }
    },
  }
</script>

<style scoped>
  .v-btn:before {
    bottom: unset !important;
  }
  .links-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .separator {
    margin: 0 5px;
    color: white;
  }

  @media(max-width: 650px) {
    .links-container {
      flex-direction: column;
    }
    .link-item {
      margin-bottom: 0.5rem;
    }
    .separator {
      margin: 0;
      display: none;
    }
  }
</style>