<template>
  <div>
    <v-app-bar
      id="app-bar-customer"
      color="white"
      :prominent="$vuetify.breakpoint.mdAndUp"
      fixed
      flat
    >
      <v-container
        class="v-app-bar-content"
      >
        <v-row>
          <!-- Start Logo -->
          <v-col
            cols="8"
            md="3"
          >
            <v-toolbar-title>
              <logo-link to="/emergencyNotification" />
            </v-toolbar-title>
          </v-col>
          <!-- End Logo -->
          <!-- Start Menu Items -->
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            cols="8"
            class="pt-8"
          >
            <div class="links-container">
              <router-link
                v-for="item in menuItems"
                :key="item.title"
                :to="item.link"
                class="w-1 mx-4 link"
              >
                <div class="font-weight-black black--text navbar-text customer-navbar-items">
                  {{ item.title }}
                </div>
              </router-link>
            </div>
          </v-col>
          <!-- End Menu Items -->
          <!-- Start User Menu Items -->
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            cols="1"
            class="pt-9"
          >
            <v-btn
              class="ml-2 float-right"
              min-width="0"
              outlined
              text
              @click="onLogout"
            >
              {{ logoutButtonText }}
              <v-icon right>
                mdi-account-arrow-right
              </v-icon>
            </v-btn>
          </v-col>
          <!-- End User Menu Items -->
          <!-- Start Hamburguer Menu Icon -->
          <v-col
            v-if="$vuetify.breakpoint.smAndDown"
            class="pt-6"
            cols="4"
          >
            <v-app-bar-nav-icon
              outlined
              class="float-right"
              color="primary"
              @click.stop="menuDrawer = !menuDrawer"
            />
          </v-col>
          <!-- End Hamburguer Menu Icon -->
        </v-row>
      </v-container>
    </v-app-bar>
    <!-- Start Navigation Menu Drawer -->
    <v-navigation-drawer
      v-model="menuDrawer"
      app
      bottom
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          active-class="primary--text text--accent-4"
        >
          <v-list-item
            v-for="item in menuItems"
            :key="item.title"
            :to="item.link"
          >
            <v-list-item-title class="text-button font-weight-black">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            class="primary-list-item-outlined"
            @click="onLogout"
          >
            <v-list-item-title class="text-button">
              {{ logoutButtonText }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- End Navigation Menu Drawer -->
  </div>
</template>

<script>
  import LogoLink from '../components/LogoLink.vue'
  import ConfidantService from '@/services/ConfidantService'
  import NotificationService from '@/services/NotificationService'
  import router from '@/router'

  export default {
    components: {
      LogoLink,
    },
    data () {
      return {
        menuDrawer: false,
        logoutButtonText: 'logout',
        router,
      }
    },
    computed: {
      menuItems () {
        return [
          { title: this.$t('emergency-notification.navbar-tab'), link: '/emergencyNotification' },
        ]
      },
    },
    created () {
      ConfidantService.getConfidantInfos()
          .then((response) => {
            this.$root.$i18n.locale = response.data.messageLanguage.locale
            this.menuItems[0].title = this.$t('emergency-notification.navbar-tab')
        })
        .catch((error) => NotificationService.error(error.message))
    },
    methods: {
      onLogout () {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('isCustomer')
        sessionStorage.removeItem('isAdmin')
        sessionStorage.removeItem('name')
        this.router.push('/login')
      },
    },
  }
</script>

<style>
  .pe-pro-seal {
    display: none;
  }
  .links-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }

  .link:hover .navbar-text{
    color: #329c36 !important;
  }

  .router-link-active .navbar-text{
    color: #329c36 !important;
  }

  @media only screen and (min-width: 1904px ) {
    .navbar-text {
      font-size: 1.2rem !important;
    }
  }

  @media only screen and (max-width: 1904px ) {
    .navbar-text {
      font-size: 0.9rem !important;
    }
  }

  @media only screen and (max-width: 1264px ) {
    .navbar-text {
      font-size: 0.85rem !important;
    }
  }

  @media only screen and (min-height: 1150px ) {
    .v-application--wrap {
      min-height: 100vh !important;
    }
  }
</style>
