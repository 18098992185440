<template>
      <v-form
        ref="form"
        v-model="formIsValid"
      >
        <v-card-title class="d-block pb-5">
          <v-row>
            <v-col
              cols="12"
            >
              <v-img
                :src="logoPictureSource"
                height="100"
                contain
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <div class="primary--text">
            {{ $t('views.anonymouspages.requestpasswordchangekeycomponent.zurcksetzenIhresPassworts') }}
          </div>
          <div class="pt-2">
            {{ $t('views.anonymouspages.requestpasswordchangekeycomponent.wennSieIhr') }}<br>
            {{ $t('views.anonymouspages.requestpasswordchangekeycomponent.hierKnnenSie') }}
          </div>
          <v-col cols="8">
            <v-text-field
              v-model="email"
              maxlength="200"
              :label="$t('views.anonymouspages.requestpasswordchangekeycomponent.bitteGebenSie')"
              :error-messages="emailErrorMessages"
              :rules="[validations.required($t('views.anonymouspages.requestpasswordchangekeycomponent.emailRequired')), validations.email($t('validations.email'))]"
            />
          </v-col>
          <v-col
            cols="12"
          >
            <test-question-captcha />
          </v-col>
          <v-btn
            :disabled="!formIsValid"
            block
            depressed
            clase="text-none"
            color="#329c36"
            @click="requestPasswordChangeKey"
          >
            {{ requestPasswordButtonText }}
          </v-btn>
          <div class="primary--text pt-3">
            {{ $t('views.anonymouspages.requestpasswordchangekeycomponent.bitteBeachtenSie') }}
          </div>
          <div class="pt-2">
            {{ $t('views.anonymouspages.requestpasswordchangekeycomponent.sobaldSieEinen') }}
          </div>
        </v-card-text>
        <link-sent-dialog-component
          ref="successDialog"
        />
      </v-form>
</template>

<script>
import validations from '@/components/formUtils/Validations'
import PublicService from '@/services/PublicService'
import NotificationService from '@/services/NotificationService'
import LinkSentDialogComponent from './LinkSentDialogComponent'
import TestQuestionCaptcha from '@/components/TestQuestionCaptcha.vue'

export default {
  components: {
    LinkSentDialogComponent,
    TestQuestionCaptcha,
  },
  data () {
    return {
      validations,
      email: '',
      emailErrorMessages: [],
      requestPasswordButtonDisabled: true,
      formIsValid: false,
    }
  },
  computed: {
    requestPasswordButtonText () {
      return this.$t('requestLink')
    },
    logoPictureSource () {
      const locale = this.$root.$i18n.locale
      if (locale === 'pt' || locale === 'ptBR') { return require('@/assets/emcomyLogo-pt.webp') }
      return require('@/assets/emcomyLogo.svg')
    },
  },
  watch: {
    formIsValid () {
      if (this.email && this.email.trim().length) {
        PublicService.isEmailPending(this.email.trim())
          .then((isPending) => {
            if (isPending.data) {
              this.emailErrorMessages = []
              this.requestPasswordButtonDisabled = true
              this.emailErrorMessages.push(this.$t('views.anonymouspages.requestpasswordchangekeycomponent.emailNotification'))
            } else {
              return PublicService.isEmailVerified(this.email.trim())
            }
          })
          .then((isVerified) => {
            if (isVerified) {
              if (isVerified.data && !this.$refs.form.validate()) {
                this.emailErrorMessages = []
                this.formIsValid = false
              } else if (!isVerified.data && this.$refs.form.validate()) {
                this.emailErrorMessages = []
                this.formIsValid = false
                this.emailErrorMessages.push(this.$t('views.anonymouspages.requestpasswordchangekeycomponent.emailNotFound'))
              } else if (isVerified.data && this.$refs.form.validate()) {
                this.emailErrorMessages = []
                this.formIsValid = true
              }
            }
          }).catch((error) => NotificationService.error(error.message))
      } else {
        this.formIsValid = true
        this.emailErrorMessages = []
      }
    },
    email (newEmail) {
      if (newEmail && newEmail.trim().length) {
        PublicService.isEmailPending(newEmail.trim())
          .then((isPending) => {
            if (isPending.data) {
              this.emailErrorMessages = []
              this.requestPasswordButtonDisabled = true
              this.emailErrorMessages.push(this.$t('views.anonymouspages.requestpasswordchangekeycomponent.emailNotConfirmed'))
            } else {
              return PublicService.isEmailVerified(newEmail.trim())
            }
          })
          .then((isVerified) => {
            if (isVerified) {
              if (isVerified.data && !this.$refs.form.validate()) {
                this.emailErrorMessages = []
                this.formIsValid = false
              } else if (!isVerified.data && this.$refs.form.validate()) {
                this.emailErrorMessages = []
                this.formIsValid = false
                this.emailErrorMessages.push(this.$t('views.anonymouspages.requestpasswordchangekeycomponent.emailNotFound'))
              } else if (isVerified.data && this.$refs.form.validate()) {
                this.emailErrorMessages = []
                this.formIsValid = true
              }
            }
          }).catch((error) => NotificationService.error(error.message))
      } else {
        this.formIsValid = true
        this.emailErrorMessages = []
      }
    },
  },
  methods: {
    requestPasswordChangeKey () {
      if (this.$refs.form.validate()) {
        this.requestPasswordButtonDisabled = true
        this.requestPasswordButtonText = this.$t('pleaseWait')
        PublicService.requestPasswordChangeKey(this.email)
          .then((response) => {
            this.$emit('closeForm')
            this.$refs.successDialog.open()
          }).catch(error => {
          NotificationService.error(null, error)
        }).finally(() => {
          this.requestPasswordButtonDisabled = false
          this.requestPasswordButtonText = this.$t('requestLink')
        })
      }
    },
    resetForm(){
      this.emailErrorMessages = []
      this.$refs.form.reset()
    }
  },
}
</script>
