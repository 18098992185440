<template>
  <v-dialog
    v-model="openDialog"
    width="650"
  >
    <v-card class="emcomy-card">
      <request-password-form ref="requestPasswordFormRef" @closeForm="close()"></request-password-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import RequestPasswordForm from '@/views/anonymousPages/components/RequestPasswordForm.vue'

  export default {
    components: {
      RequestPasswordForm,
    },
    data () {
      return {
        openDialog: false,
      }
    },
    methods: {
      open () {
        this.openDialog = true
        this.$refs.requestPasswordFormRef.resetForm()
      },
      close () {
        this.openDialog = false
      },
    },
  }
</script>
